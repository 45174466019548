/* Agrega estas clases en tu archivo de estilos CSS */
.carousel {
    position: relative;
    overflow: hidden;
    display: flex;
  }
  
  .carousel-properties {
    width: 1200px;
    height: 750px;
    object-fit: cover;
    left: 0;
  }
  .image-opacity {
    animation: fadeOpacity 0.5s ease-in-out; /* Adjusted durations */
  }
  
  @keyframes fadeOpacity {
    0% {
      opacity: 0.7; /* Start with half opacity */
    }
    100% {
      opacity: 1; /* End with full opacity */
    }
  }
  @media screen and (max-width: 600px) {
    .carousel-properties {
      width: 100%;
      height: 300px;
      object-fit: cover;
      left: 0;
    }
  }
  
  