  .header {
    height: 133px;
    width: 100%;
    z-index: 4;
    transition: .3s ease-in;
    background-color: #1f1f1f;
}
@media screen and (max-width: 600px) {
  .header {
      height: 103px;
  }
}
.header-bg{
    background-color: #1f1f1f;
}
.logo{
    transition: .3s ease-in;
}
.active {
    border-bottom: 2px solid #1daaba ;
    transition: all 0.5s; 
  }

  .a-h {
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s; 
  }
  
  .a-h:hover {
    border-bottom: 2px solid #1daaba;
  }

  .whatsapp {
    border-color: #ffffff;
    background-color: transparent;
    transition: border-color 0.3s, background-color 0.3s; /* Añade la propiedad transition */
  }
  
  .whatsapp:hover {
    border-color: #1daaba;
    background-color: #1daaba;
  }


  .div-dropdown {
    display: none;
    opacity: 0; 
    transition: 0.3s; 
    border-top: 4px solid #1daaba ;
  }
  
  /* Estilos para el enlace de "Propiedades" cuando está en estado de hover */
  a[href="/places"]:hover .div-dropdown {
    display: block;
    opacity: 1; /* Mostrar el div al hacer hover */
  }
  
  