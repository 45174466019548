.button-chat{
    background-color: #1f1f1f;
    color: white;
    transition:  color 0.3s;
}
.button-chat:hover{
    color: #1daaba;
}
.img-about{
width: auto;
}
/* @media screen and (max-width: 600px) {
    .button-chat{

    }
  } */