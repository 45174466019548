.border-container{
  border: 3px solid #1f1f1f; /* Borde negro de 2px */
  border-radius: 50px; /* Curvatura de las esquinas */

}

.img-div{
  border-top-left-radius: 50px; /* Curvatura de la esquina superior izquierda */
  border-top-right-radius: 50px; /* Curvatura de la esquina superior derecha */
  background-color: #1f1f1f;
}

.button-place {
    position: absolute;
    top: 89%;
    left: 80%;
    transform: translate(-50%, -50%);
    border: 4px solid rgba(0, 0, 0, 0.4); /* Borde negro */
    background: rgba(73, 73, 73, 0.4);
    color: #e3e1e1; /* Color del texto */
    transition: 0.3s; /* Transición para el efecto hover */
    z-index: 2;
  
  }
  
  .button-place:hover {
    background: #1f1f1f; /* Fondo negro en hover */
    color: white; /* Texto blanco en hover */
  }

  .img-scale {
    overflow: hidden; /* Para recortar el contenido que se desborde */
    transition: transform 1s; /* Agrega una transición suave para el efecto */
    height: 400px;
  }
  
  .img-scale:hover {
    transform: scale(1.2); /* Aplica un aumento de escala del 120% en el hover */
  }

  .shadow-bottom {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  @media screen and (max-width: 600px) {
    .img-scale {
      height: 200px;
    }
  }