.shadow-place {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:  rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s; /* Aumenta la duración de la transición a 0.5 segundos */
}
 
.shadow-place:hover {
  background-color:  rgba(0, 0, 0, 0);
}
.title-place{
  font-size: 50px;
}

@media screen and (max-width: 600px) {
  .title-place{
    font-size: 28px;
  }
}